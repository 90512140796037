import React, { useState } from 'react'
import { Link } from 'gatsby'

import NavSocialMenu from './NavSocialMenu'
import NavMenuButton from './NavMenuButton'
import NavHiddenMenu from './NavHiddenMenu'

import { HOME_URL } from '../../../utils/urls'
import useHideOnScroll from './useHideOnScroll'
import styles from './Nav.module.css'

const isHomepage = location => location && location.pathname === HOME_URL

const Nav = ({ location }) => {
  const [show, setShow] = useState(false)
  const [ref] = useHideOnScroll()

  return (
    <header className={styles.header} ref={ref}>
      <NavHiddenMenu show={show} />
      <div className={styles.headerInner}>
        <div className={styles.headerLeft}>
          <NavSocialMenu />
        </div>
        <div className={styles.headerRight}>
          {!isHomepage(location) && (
            <Link className={styles.home} to={HOME_URL}>
              LUXO
            </Link>
          )}

          <NavMenuButton show={show} handleClick={() => setShow(val => !val)} />
        </div>
      </div>
    </header>
  )
}

export default Nav
