import React from 'react'
import cx from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import styles from './NavSocialMenu.module.css'

const SocialLink = ({ icon, href }) => {
  return (
    <a href={href} className={styles.socialLink}>
      <FontAwesomeIcon
        icon={['fab', icon]}
        className={cx(styles.socialLinkIcon, styles[icon])}
      />
    </a>
  )
}

const NavSocialMenu = () => {
  return (
    <nav className={styles.social}>
      <SocialLink icon="soundcloud" href="https://soundcloud.com/luxo" />
      <SocialLink
        icon="spotify"
        href="https://open.spotify.com/artist/3ECUg6kTV5tp9vJ2UFNYZ9?si=1xzfuV2JSPuusnS74qCHaA"
      />
      <SocialLink
        icon="facebook-square"
        href="https://www.facebook.com/iamluxo/"
      />
      <SocialLink
        icon="instagram"
        href="https://www.instagram.com/luxomusic/"
      />
    </nav>
  )
}

export default NavSocialMenu
