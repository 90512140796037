import React from 'react'
import { Link } from 'gatsby'
import cx from 'classnames'

import { TUTORING_URL } from '../../../../utils/urls'
import styles from './NavHiddenMenu.module.css'

const NAV_ITEMS = [
  { title: 'Contact', href: 'mailto:luxobeats@gmail.com' },
  { title: 'Feedback & Mentorship', to: TUTORING_URL },
]

const NavHiddenMenuItem = ({ to, href, children }) => {
  const Component = href ? 'a' : Link

  return (
    <div className={styles.navMenuItem}>
      <Component to={to} href={href} className={styles.navMenuItemLink}>
        {children}
      </Component>
    </div>
  )
}

const NavHiddenMenu = ({ show }) => {
  return (
    <div className={cx(styles.nav, { [styles.navActive]: show })}>
      <div className={styles.navBg} />
      <div className={styles.navMenuWrapper}>
        <div
          className={cx(styles.navMenuAnimate, {
            [styles.navMenuAnimateActive]: show,
          })}
        >
          <nav className={styles.navMenu}>
            {NAV_ITEMS.map(({ title, to, href }) => (
              <NavHiddenMenuItem to={to} href={href} key={title}>
                {title}
              </NavHiddenMenuItem>
            ))}
          </nav>
        </div>
      </div>
    </div>
  )
}

export default NavHiddenMenu
