import React from 'react'
import cx from 'classnames'

import styles from './NavMenu.module.css'

const NavMenuButton = ({ show, handleClick }) => {
  return (
    <button
      className={cx(styles.menu, { [styles.isActive]: show })}
      type="button"
      onClick={handleClick}
    >
      <div className={styles.menuLines}>
        <div className={styles.menuLine}></div>
        <div className={styles.menuLine}></div>
        <div className={styles.menuLine}></div>
      </div>
    </button>
  )
}

export default NavMenuButton
