import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import styles from './Button.module.css'

const Button = ({ children, className, component, theme, ...props }) => {
  const buttonProps = {
    className: cx(styles.button, styles[theme], className),
    ...props,
  }

  if (component) {
    const Component = component
    return <Component {...buttonProps}>{children}</Component>
  }

  return <button {...buttonProps}>{children}</button>
}

Button.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  className: PropTypes.string,
  component: PropTypes.elementType,
  theme: PropTypes.string,
}

export default Button
