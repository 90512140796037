// This file adds FontAwesome icons to our library. By adding them to our
// library, we make the icons available for use with the <FontAwesomeIcon />
// component anywhere in our app.
//
// Example:
//
// import { library } from '@fortawesome/fontawesome-svg-core'
// import { faSoundcloud } from '@fortawesome/free-brands-svg-icons'
//
// library.add(faSoundcloud)
//
// <FontAwesomeIcon icon={['fab', 'soundcloud']} />

import { library, config } from '@fortawesome/fontawesome-svg-core'
import {
  faSoundcloud,
  faSpotify,
  faFacebookSquare,
  faInstagram,
} from '@fortawesome/free-brands-svg-icons'

// Fixes server-side rendering of icons loading too large
// https://stackoverflow.com/questions/49781726/react-font-awesome-renders-big-icon-until-scales-down
config.autoAddCss = false

const importIcons = () => {
  library.add(faSoundcloud, faSpotify, faFacebookSquare, faInstagram)
}

export default importIcons
