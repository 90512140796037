import React from 'react'
import importIcons from '../../utils/icons'
import SEO from './SEO'
import Nav from '../common/Nav'
import Footer from '../common/Footer'

importIcons()

const Layout = ({ children, location, ...SEOProps }) => {
  return (
    <div className="site">
      <SEO location={location} {...SEOProps} />
      <Nav location={location} />
      <div className="site-content">{children}</div>
      <Footer />
    </div>
  )
}

export default Layout
