import { useRef, useEffect, useCallback } from 'react'
import throttle from 'lodash/throttle'

import { isBrowser } from '../../../utils/environment'
import styles from './Nav.module.css'

const SCROLL_Y_DISTANCE_TO_SEE_NAV = 10
const SCROLL_HANDLER_THROTTLE_TIMEOUT = 100

let timeout
let prevScrollY

const useHideOnScroll = () => {
  const navEl = useRef(null)

  const handleScroll = useCallback(
    throttle(() => {
      if (!navEl.current) return
      if (timeout) window.cancelAnimationFrame(timeout)

      timeout = window.requestAnimationFrame(() => {
        const scrollY = window.scrollY
        const isScrollingUp = prevScrollY > scrollY
        const isScrollingDown =
          scrollY - prevScrollY > SCROLL_Y_DISTANCE_TO_SEE_NAV

        if (scrollY >= 10) {
          navEl.current.classList.add(styles.hasShadow)
        } else {
          navEl.current.classList.remove(styles.hasShadow)
        }

        if (scrollY <= 50) {
          navEl.current.classList.remove(styles.navHidden)
        } else if (isScrollingUp) {
          navEl.current.classList.remove(styles.navHidden)
        } else if (isScrollingDown) {
          navEl.current.classList.add(styles.navHidden)
        }

        prevScrollY = scrollY
      })
    }, SCROLL_HANDLER_THROTTLE_TIMEOUT),
    [navEl]
  )

  useEffect(() => {
    if (!handleScroll) return
    if (isBrowser) window.addEventListener('scroll', handleScroll, false)

    return () => {
      if (isBrowser) window.removeEventListener('scroll', handleScroll, false)
    }
  }, [handleScroll])

  return [navEl]
}

export default useHideOnScroll
