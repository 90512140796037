import React from 'react'
import styles from './Footer.module.css'
import { Link } from 'gatsby'
import { TUTORING_URL } from '../../../utils/urls'

const Footer = () => {
  return (
    <footer className={styles.container}>
      <div className={styles.flex}>
        <header className={styles.header}>
          <h3>LUXO</h3>
        </header>
        <nav className={styles.nav}>
          <div>
            <a href="mailto:luxobeats@gmail.com" className={styles.content}>
              Contact
            </a>
          </div>
          <div>
            <a href="https://soundcloud.com/luxo" className={styles.content}>
              Soundcloud
            </a>
          </div>
          <div>
            <a
              href="https://open.spotify.com/artist/3ECUg6kTV5tp9vJ2UFNYZ9?si=1xzfuV2JSPuusnS74qCHaA"
              className={styles.content}
            >
              Spotify
            </a>
          </div>
          <div>
            <a
              href="https://www.beatport.com/artist/luxo/284255"
              className={styles.content}
            >
              Bandcamp
            </a>
          </div>
          <div>
            <Link to={TUTORING_URL} className={styles.content}>
              Get feedback or mentoring
            </Link>
          </div>
        </nav>
      </div>
      <div className={styles.copyright}>©️ Luxo 2020</div>
    </footer>
  )
}
export default Footer
